<template>
  <div class="toggle" :class="{ active: isTrue }" @click="isTrue = !isTrue"></div>
</template>
<script>
export default {
  name: 'Toggle',
  props: ['selected'],
  data() {
    return {
      isTrue: false
    }
  },
  created() {
    if(this.selected) {
      this.isTrue = true;
    }
  }
}
</script>
