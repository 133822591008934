<template>
  <div class="tool__controls">
    <span class="tool__controls-device" :data-tooltip="'Device: ' + device" tooltip-top>
      <font-awesome-icon :icon="screenshotDevice" size="lg" />
      <template v-if="device.toLowerCase() != 'desktop'">{{ device }}</template>
    </span>
    <span v-if="device.toLowerCase() == 'desktop'" class="tool__controls-resolution" data-tooltip="Opløsning" tooltip-top>
      {{ resolution.width }} x {{ resolution.height }}
    </span>
    <span class="tool__controls-incognito" :data-tooltip="incognito ? 'Incognito browser' : 'Standard browser'" tooltip-top>
      <font-awesome-icon :icon="screenshotincognito" size="lg" />
    </span>
    <span v-if="fullscreen" class="tool__controls-fullscreen" data-tooltip="Tag fullscreen screenshot" tooltip-top >
      <font-awesome-icon icon="expand" size="lg" />
    </span>
    <span class="tool__controls-edit" @click="showControls = true">
      Indstillinger <font-awesome-icon icon="ellipsis-h" size="lg" />
    </span>
  </div>

  <teleport to="#app">
    <div v-if="showControls" @click.self="showControls = false" class="modal">
      <div class="modal__box">
        <header>
          <h4>Indstillinger</h4>
          <span class="close" @click="showControls = false">
            <font-awesome-icon icon="times" />
          </span>
        </header>
        <section>
          <div class="modal__row">
            <label>Vælg device</label>
            <div class="modal__control">
                <SmartDropdown @updatePicked="deviceChange" ref="devicePicker" :selected="currentOptions.device" :options="[desktopOption].concat(puppeteerDevices)" />
            </div>
          </div>
          <div class="modal__row" v-if="currentOptions.device === 0">
            <label>Vælg skærmopøsning</label>
            <div class="modal__control">
                <SmartDropdown ref="resolutionPicker" :selected="currentOptions.resolution" :options="desktopResolutions" />
            </div>
          </div>
          <div class="modal__row">
            <label>Screenshot af hele siden</label>
            <div class="modal__control">
                <Toggle ref="fullscreenPicker" :selected="fullscreen" />
            </div>
          </div>
          <div class="modal__row">
            <label>Brug incognito vindue</label>
            <div class="modal__control">
                <Toggle ref="incognitoPicker" :selected="incognito" />
            </div>
          </div>
        </section>
        <footer>
          <button @click="saveSettings" class="confirm">Gem indstillinger</button>
          <button @click="showControls = false">Annuller</button>          
        </footer>
      </div>
    </div>
  </teleport>
</template>
<script>
import { devices } from '../assets/devices';
import SmartDropdown from './SmartDropdown.vue';
import Toggle from './Toggle.vue';

export default {
  name: 'Controls',
  props: ['device', 'resolution', 'incognito', 'fullscreen'],
  emits: ['settingsChange'],
  components: { SmartDropdown, Toggle },
  computed: {
    screenshotDevice() {
      return this.device.toLowerCase() == 'desktop' ? 'desktop' : 'mobile-alt'
    },
    screenshotincognito() {
      return this.incognito ? 'user-secret' : 'user'
    }
  },
  data() {
    return {
      showControls: false,
      puppeteerDevices: devices,
      desktopOption:
      {
        name: "Desktop",
        userAgent: "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/93.0.4577.82 Safari/537.36",
        viewport: {
          deviceScaleFactor: 1,
          hasTouch: false,
          height: 1080,
          isLandscape: false,
          isMobile: false,
          width: 1920,
        }
      },
      currentOptions: {
        device: 0,
        resolution: 1,
      },
      desktopResolutions: [
        {
          name: '1440 x 900',
          width: 1440,
          height: 900
        },
        {
          name: '1920 x 1080',
          width: 1920,
          height: 1080
        },
        {
          name: '2560 x 1440',
          width: 2560,
          height: 1440
        },
        {
          name: '3840 x 2160',
          width: 3840,
          height: 2160
        }
      ]
    }
  },
  created() {
    document.body.addEventListener('keyup', (event) => {
      if(event.key === 'Escape') {
        this.showControls = false
      }
    })
  },
  methods: {
    deviceChange(index) {
      this.currentOptions.device = index;
    },
    saveSettings() {
      let settings = {};
      
      let deviceIndex = this.$refs.devicePicker.pickedIndex;
      this.currentOptions.device = deviceIndex

      if(deviceIndex > 0) {
        settings.device = this.puppeteerDevices[deviceIndex - 1].name
      } else {
        settings.device = this.desktopOption.name;
      }

      if(this.$refs.resolutionPicker) {
        let resolutionIndex = this.$refs.resolutionPicker.pickedIndex

        this.currentOptions.resolution = resolutionIndex
        
        settings.resolution = {
          width: this.desktopResolutions[resolutionIndex].width,
          height: this.desktopResolutions[resolutionIndex].height
        }
      }

      settings.incognito = this.$refs.incognitoPicker.isTrue
      settings.fullscreen = this.$refs.fullscreenPicker.isTrue

      this.$emit('settingsChange', settings);

      this.showControls = false;
    }
  }
}
</script>
