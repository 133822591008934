<template>
  <div class="tool" :class="{'tool--is-working': isWorking}">
    <h1>Screenshot <span>Tool</span></h1>
    <Controls @settingsChange="setSettings" :device="browserSettings.device" :resolution="browserSettings.resolution" :incognito="browserSettings.incognito" :fullscreen="browserSettings.fullscreen" />
    <input type="url" v-model="url" @keyup.enter="takeScreenshot" placeholder="Type url, hit enter to start" class="tool__url">
    <div v-if="showResult" class="tool__result">
      <a :href="'data:image/png;base64,' + imageData" download="screenshot.png">
        <img class="tool__image" :src="'data:image/png;base64,' + imageData" :alt="'Screenshot of: ' + url">
      </a>
    </div>
  </div>
</template>

<script>
import { initializeApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';

import Controls from './components/Controls.vue';

export default {
  name: 'App',
  components: { Controls },
  data() {
    return {
      browserSettings: {
        device: 'desktop',
        resolution: {
          width: 1920,
          height: 1080
        },
        incognito: false,
        fullscreen: true
      },
      isWorking: false,
      showResult: false,
      imageData: '',
      url: ''
    }
  },
  created() {
    const firebaseConfig = {
      apiKey: "AIzaSyAweCpIJo1Ic_JM_KzX176aBpZ_NRG6XZw",
      authDomain: "screenshot-tool-2d1b4.firebaseapp.com",
      projectId: "screenshot-tool-2d1b4",
      storageBucket: "screenshot-tool-2d1b4.appspot.com",
      messagingSenderId: "644194837420",
      appId: "1:644194837420:web:4b7b20ba61c51299d75009"
    };

    // eslint-disable-next-line 
    const firebaseApp = initializeApp(firebaseConfig);
  },
  methods: {
    setSettings(settings) {
      this.browserSettings.device = settings.device

      if(settings.resolution) {
        this.browserSettings.resolution.width = settings.resolution.width
        this.browserSettings.resolution.height = settings.resolution.height
      }

      this.browserSettings.incognito = settings.incognito

      this.browserSettings.fullscreen = settings.fullscreen
    },
    async takeScreenshot() {
      this.showResult = false;
      this.imageData = '';
      this.isWorking = true;

      const functions = getFunctions();
      const takeScreenshot = httpsCallable(functions, 'takeScreenshot');

      takeScreenshot({
        url: this.url,
        settings: this.browserSettings
      })
      .then((result) => {
        const data = result.data;

        if(data.image) {
          this.imageData = data.image;
          this.showResult = true;
        }

        this.isWorking = false;
      })
      .catch((error) => {
        console.log(error);

        this.isWorking = false;
      })
    }
  }
}
</script>

<style lang="scss">
#app {
  min-height: 100vh;
}
</style>
