<template>
  <div class="smart-dropdown" @click.self="showOptions = !showOptions" :class="{ open: showOptions }" >
    <div @click="showOptions = !showOptions" class="smart-dropdown__selected"> {{ pickedName }} </div>
    <div class="smart-dropdown__options">
      <div v-for="(option, index) in options" :key="index" @click="pickOption(index)" class="smart-dropdown__option" :class="{ 'smart-dropdown__option--picked': index == pickedIndex }">
        {{ option.name }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Smart dropdown',
    props: ['selected', 'options'],
    emits: ['updatePicked'],
    data() {
      return {
        showOptions: false,
        pickedIndex: null,
        nonePicked: 'Vælg fra listen'
      }
    },
    computed: {
      pickedName() {
        let name = this.nonePicked;
        
        if(this.pickedIndex != null) {
          name = this.options[this.pickedIndex].name
        }

        return name;
      },
      // Needs to be figured out
      // sortedOptions() {
      //   let sorted = this.options
        
      //   if(!this.pickedIndex != null) {
      //     sorted = sorted.unshift(sorted.splice(this.pickedIndex, 1)[0])
      //   }
        
      //   return sorted;
      // }
    },
    created() {
      if(this.selected != null) {
        this.pickedIndex = this.selected;
      }      
    },
    methods: {
      pickOption(index) {
        this.pickedIndex = index;
        this.showOptions = false;
        this.$emit('updatePicked', index);
      }
    }
  }
</script>